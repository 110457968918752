import React from 'react';
import Container from 'container';
import Img from 'img';
import { Link } from 'gatsby';
import dataEN from './data';
import dataES from './data-es';
import s from './footer.module.scss';

// Main Component
export default function footer() {
  const data = process.env.GATSBY_SPANISH ? dataES : dataEN;
  return (
    <>
      <Container className={s.section}>
        <div className={s.wrapper}>
          <Img src="homero1" alt="footer" className={s.img} />
          <div className={s.overlay} />
          <div className={s.content}>
            <div className={s.footerBlock}>
              <h5 dangerouslySetInnerHTML={{ __html: data.block1.headline }} />
              <p dangerouslySetInnerHTML={{ __html: data.block1.paragraph }} />
              <h6><Link to={data.block1.link1.href}>{data.block1.link1.text}</Link></h6>
            </div>
            <div className={s.footerBlock}>
              <h5 dangerouslySetInnerHTML={{ __html: data.block2.headline }} />
              {data.block2.paragraphs.map(paragraph => (
                <p dangerouslySetInnerHTML={{ __html: paragraph }} />
              ))}
            </div>
            <div className={s.footerBlock}>
              <h5 dangerouslySetInnerHTML={{ __html: data.block3.headline }} />
              <ul>
                {data.block3.links.map(({ name, link }) => (
                  <li>
                    <Link to={link} key={name}>
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={s.footerBlock}>
              <h5 dangerouslySetInnerHTML={{ __html: data.block4.headline }} />
              <ul>
                {data.block4.links.map(({ name, link }) => (
                  <li>
                    <Link to={link} key={name}>
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={s.logos}>
              <img className={s.bbblogo} src="/images/logo-bbb.svg" alt="bbb-logo" />
              <img className={s.nasplogo} src="/images/logo-nasp.svg" alt="bbb-logo" />
            </div>
          </div>
        </div>
      </Container>
      <div className={s.bottomFooter}>
        <Container noSection className={s.container}>
          <p dangerouslySetInnerHTML={{ __html: data.bottomFooter }} />
        </Container>
      </div>
    </>
  );
}
