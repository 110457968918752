import React, { useState, useRef, useEffect } from 'react';
import Icon from 'icon';
import { Link } from 'gatsby';
import s from '../header.module.scss';

export default function ListItem({ name, link, sublinks, icon, closeNav, open, setOpen }) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState(null);
  useEffect(
    () => {
      if (content && content.current) {
        setHeight(content.current.offsetHeight + 20);
      }
    },
    [content],
  );

  function updateHeight() {
    if (sublinks) setHeight(content.current.offsetHeight + 20);
  }

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(
    () => {
      if (!open) {
        setTimeout(() => {
          setActive(false);
        }, 300);
      }
    },
    [open],
  );

  function handleClose(e) {
    setOpen(true);
    if (!sublinks) return closeNav();
    e.preventDefault();
    return setActive(!active);
  }

  return (
    <div className={`${s.listItem} ${s.toggledLi}`}>
      <Link to={link} className={`${s.navLink} ${active ? s.bg : ''}`} onClick={handleClose} key={name}>
        {name}
        {' '}
        {icon ? <Icon icon={active ? 'chevronup' : 'chevrondown'} className={s.chevrondown} /> : null}
      </Link>
      {sublinks && (
        <ul style={{ maxHeight: active ? height : 0 }} className={`${s.dropdown} ${active && s.active}`}>
          <div ref={content}>
            {sublinks.map(sublink => (
              <li className={s.ddListItem} key={sublink.link} onClick={() => setActive(false)}>
                <Link to={sublink.link} className={`${s.ddLink} ${sublink.mobileOnly && s.mobileOnly}`} onClick={closeNav}>
                  {sublink.name}
                </Link>
              </li>
            ))}
          </div>
        </ul>
      )}
    </div>
  );
}
