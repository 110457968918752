import React from 'react';
import Layout from '@/layout';
import TemplatesProvider from 'shared/templates-context';
import 'styles/project.scss';

export default {
  wrapPageElement: ({ element, props }) => {
    const { acfKeys, acfData, slug: pageSlug } = props.pageContext;
    const slug = pageSlug || props.pageContext.slug;
    const isTemplates = slug === '/templates';
    const layoutProps = { ...(acfKeys ? { ...props, acfKeys, acfData } : { ...props }) };
    const templateNames = isTemplates && acfData.map(({ acf_fc_layout }) => acf_fc_layout);
    return templateNames ? (
      <TemplatesProvider templates={templateNames}>
        <Layout {...layoutProps}>{element}</Layout>
      </TemplatesProvider>
    ) : (
      <Layout {...layoutProps}>{element}</Layout>
    );
  }
};
