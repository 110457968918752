import React, { useState, useRef, useEffect } from 'react';
import Icon from 'icon';
import { Link } from 'gatsby';
import s from '../header.module.scss';

export default function ListItem({ name, link, sublinks, closeNav, open, setOpen }) {
  // State
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState(null);
  const visRef = useRef(null);

  // functions
  function updateHeight() {
    if (sublinks) setHeight(content.current.offsetHeight + 20);
  }

  function handleClose(e) {
    setOpen(true);
    if (!sublinks) return closeNav();
    e.preventDefault();
    document.addEventListener('click', handleOutsideClick);
    return setActive(!active);
  }

  function handleOutsideClick(e) {
    if(!visRef.current.contains(e.target)) {
      setActive(false);
    }
  }

  // Effects
  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => {window.removeEventListener('resize', updateHeight)};
  }, []);

  useEffect(
    () => {
      if (content && content.current) {
        setHeight(content.current.offsetHeight + 20);
      }
    },
    [content],
  );

  useEffect(
    () => {
      if (!open) {
        setActive(false);
      }
    },
    [open],
  );

  return (
    <div ref={visRef} className={`${s.listItem} ${s.toggledLi}`}>
      <Link to={link} className={`${s.navLink} ${active ? s.bg : ''}`} onClick={(e)=>{ handleClose(e)} } key={name}>
        {name}
        {' '}
        {sublinks ? <Icon icon={active ? 'chevronup' : 'chevrondown'} /> : null}
      </Link>
      {sublinks && (
        <ul style={{ maxHeight: active ? height : 0 }} className={`${s.dropdown} ${active && s.active}`}>
          <div ref={content}>
            {sublinks.map(({ link, name }) => (
              <li className={s.ddListItem} key={link} onClick={() => setActive(false)}>
                <Link to={link} className={`${s.ddLink}`} onClick={closeNav}>
                  {name}
                </Link>
              </li>
            ))}
          </div>
        </ul>
      )}
    </div>
  );
}
