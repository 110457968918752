import React from 'react';

class IconComponent extends React.Component {
  renderIcon = () => require(`./${this.props.icon}`).default;

  render() {
    const { icon, ...other } = this.props;
    const Icon = this.renderIcon();
    return <Icon {...other} />;
  }
}

export default IconComponent;
