import React from 'react';

export default ({ className, ...other }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44.475 44.475"
    className={`icon w16 ${className || ''}`}
    {...other}
  >
    <g transform="translate(-411.991 -12161)">
      <g fill="none" stroke="currentColor" strokeWidth="3">
        <circle cx="434.228" cy="12183.237" r="22.237" stroke="none" />
        <circle cx="434.228" cy="12183.237" fill="none" r="20.737" />
      </g>
      <g fill="currentColor">
        <path
          d="m0 0h3v9h-3z"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 426.663 12183.172)"
        />
        <path
          d="m0 0h3v13h-3z"
          transform="matrix(.70710678 .70710678 -.70710678 .70710678 440.442 12177.636)"
        />
      </g>
    </g>
  </svg>
);
