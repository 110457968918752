export default {
  block1: {
    headline: 'About us',
    paragraph: 'Fortune Settlement Solutions are Structured Settlement and Annuity buyers. We pride ourselves in our expert staff and dedicated customer service. We focus on liquidating your long-term payments and helping you get cash now with the best deal possible.',
    link1: {
      href: '/about-us/',
      text: 'Meet Our Team',
    }
  },
  block2: {
    headline: 'Contact us',
    paragraphs: [
      '8000 Governors Square Blvd. Miami Lakes, FL 33016',
      '<a href="tel:+18337367886">(833) 736-7886</a>',
      '<a href="mailto:info@fortunesettlements.com">info@fortunesettlements.com</a>',
    ],
  },
  block3: {
    headline: 'Useful links',
    links: [
      {
        name: 'How to Choose the Right Structured Settlement Buyer',
        link: '/how-to-choose-the-right-structured-settlement-buyer/',
      },
      {
        name: 'What is a Structured Settlement and Can I Sell My Payments?',
        link: '/what-is-a-structured-settlement-and-can-i-sell-my-payments/',
      },
      {
        name: 'Structured Settlement Pros and Cons',
        link: '/structured-settlement-pros-and-cons/',
      },
      {
        name: 'What\'s Next Once I Sell My Settlement Payments?',
        link: '/whats-next-once-i-sell-my-settlement-payments/',
      },
      {
        name: 'Privacy Policy',
        link: '/privacy-policy/',
      }
    ],
  },
  block4: {
    headline: 'Service Areas',
    links: [
      {
        name: 'Cash For Settlements',
        link: '/money-for-structured-settlements/',
      },
      {
        name: 'Settlement Funding',
        link: '/settlement-funding/',
      },
      {
        name: 'Selling annuities',
        link: '/selling-an-annuity/',
      },
      {
        name: 'Selling lottery payments',
        link: '/selling-lottery-payments/',
      }
    ],
  },
  bottomFooter: 'Copyright © fortunesettlements - Powered by <a href="https://cxncollective.com">CXN Collective</a>',
};
