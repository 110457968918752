import React from 'react';
import { Link } from 'gatsby';
import { scrollto } from '../../../../utils/scrollto';
import s from './button.module.scss';

export default ({
  href,
  native,
  children,
  className,
  secondary,
  tertiary,
  big,
  small,
  outlined,
  type,
  html,
  scroll,
  ...props
}) => {
  const secondaryClasses = secondary ? s.secondary : '';
  const tertiaryClasses = tertiary ? s.tertiary : '';
  const colorClasses = secondaryClasses || tertiaryClasses || '';
  const bigClass = big ? s.big : '';
  const smallClass = small ? s.small : '';
  const sizeClasses = bigClass || smallClass || '';
  const classes = `${s.default} ${className
    || ''} ${colorClasses} ${sizeClasses} ${outlined ? s.outlined : ''}`;
  function handleScroll() {
    scrollto(scroll);
  }
  if (scroll) {
    return html ? (
      <button
        type={type || 'button'}
        {...props}
        onClick={() => handleScroll()}
        className={classes}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    ) : (
      <button type={type || 'button'} {...props} className={classes} onClick={() => handleScroll()}>
        {children}
      </button>
    );
  }
  if (href && native) {
    return html ? (
      <a
        {...props}
        href={href}
        className={classes}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    ) : (
      <a {...props} className={classes} href={href}>
        {children}
      </a>
    );
  }
  if (href) {
    return html ? (
      <Link
        {...props}
        to={href}
        className={classes}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    ) : (
      <Link {...props} to={href} className={classes}>
        {children}
      </Link>
    );
  }
  return html ? (
    <button
      type={type || 'button'}
      {...props}
      className={classes}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  ) : (
    <button type={type || 'button'} {...props} className={classes}>
      {children}
    </button>
  );
};
