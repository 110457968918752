import React from 'react';

export default ({ className, ...other }) => (
  <svg
    {...other}
    className={`icon wfortune-logo ${className || ''}`}
    viewBox="0 0 550.68 138.084"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      transform="translate(0 -5.983)"
    >
      <g id="Symbol_2_1" data-name="Symbol 2 – 1">
        <text
          id="F_RTUNE"
          data-name="F     RTUNE"
          transform="translate(0 111.87)"
          fontSize="98.34"
          fontFamily="Helvetica-Light, Helvetica"
          fontWeight="300"
        >
          <tspan x="0" y="0" xmlSpace="preserve">
            F{'     '}
          </tspan>
          <tspan y="0" letterSpacing="0.01em">
            R
          </tspan>
          <tspan y="0">TUNE</tspan>
        </text>
      </g>
      <g id="Symbol_1_1" data-name="Symbol 1 – 1">
        <text
          id="SETTLEMENT_SOLUTIONS"
          data-name="SETTLEMENT SOLUTIONS"
          transform="translate(221.68 139.79)"
          fontSize="16.23"
          fontFamily="Helvetica"
        >
          <tspan x="0" y="0" letterSpacing="0.4em">
            S
          </tspan>
          <tspan y="0" letterSpacing="0.42em">
            E
          </tspan>
          <tspan y="0" letterSpacing="0.44em">
            T
          </tspan>
          <tspan y="0" letterSpacing="0.4em">
            TLEMENT SO
          </tspan>
          <tspan y="0" letterSpacing="0.36em">
            L
          </tspan>
          <tspan y="0" letterSpacing="0.4em">
            UTION
          </tspan>
          <tspan y="0">S</tspan>
        </text>
      </g>
      <path
        id="Path_2"
        data-name="Path 2"
        d="M204.23,71.55v.1a1,1,0,0,1-2,0v-.1a1,1,0,1,1,2,0Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M197.12,101v.09a1.138,1.138,0,1,1-2.05-.99v-.07l.05-.08a1.13,1.13,0,0,1,2,1Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M178,125.14l-.08.06a2.89,2.89,0,0,1-3.6-4.51,3.882,3.882,0,0,1,.33-.23h.09a2.88,2.88,0,0,1,3.26,4.73Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M149.06,138h-.09a4.7,4.7,0,0,1-1.58-9.27,3.617,3.617,0,0,1,.46-.06h.1a4.7,4.7,0,0,1,1.11,9.32Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M116.88,135.16h-.1a5.77,5.77,0,1,1,3.73-10.91l.37.13h.09a5.77,5.77,0,0,1-4.09,10.78Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M89.35,117.32l-.07-.07A6.763,6.763,0,1,1,99.15,108l.25.29.06.07a6.768,6.768,0,1,1-10.11,9Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M73.17,88.27v-.1a7.59,7.59,0,1,1,14.63-4,3.058,3.058,0,0,1,.1.41v.1a7.59,7.59,0,0,1-14.74,3.61Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_9"
        data-name="Path 9"
        d="M72.29,54.64v-.1A8.526,8.526,0,0,1,89,57.94a2.545,2.545,0,0,1-.16.66v.09a8.49,8.49,0,0,1-16.47-4Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_10"
        data-name="Path 10"
        d="M87.09,23.83l.06-.08a9.753,9.753,0,1,1,15.14,12.3c-.17.21-.37.43-.56.63l-.07.07A9.75,9.75,0,0,1,87.09,23.83Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M115.17,3.6h.09a10.42,10.42,0,1,1,7.4,19.481h-.1A10.42,10.42,0,0,1,115.17,3.6Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_12"
        data-name="Path 12"
        d="M149.6.16h.11a9.66,9.66,0,0,1-2.31,19.15h-.1A9.671,9.671,0,0,1,148.42,0a8.86,8.86,0,0,1,1.18.16Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M180.2,14.68l.07.06A6.87,6.87,0,0,1,172.52,26l-.09-.05a6.88,6.88,0,1,1,6.38-12.191,7.67,7.67,0,0,1,1.39.921Z"
        transform="translate(-13 6)"
      />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M198.78,41.16v.09a3.024,3.024,0,0,1-5.35,2.82l-.05-.09a3.048,3.048,0,0,1,5.06-3.4,3.61,3.61,0,0,1,.34.58Z"
        transform="translate(-13 6)"
      />
    </g>
  </svg>
);
