import React from 'react';

export default ({ className, ...other }) => (
  <svg {...other} className={`icon w16 ${className || ''}`} viewBox="0 0 306 306" xmlns="http://www.w3.org/2000/svg">
    <polygon
      fill="currentColor"
      points="153,230.775 247.35,299.625 211.65,187.425 306,121.125 191.25,121.125 153,6.375 114.75,121.125 0,121.125     94.35,187.425 58.65,299.625"
      className=""
    />
  </svg>
);
